import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));

const Login2 = React.lazy(() => import('pages/account/Login2'));
const Logout2 = React.lazy(() => import('pages/account/Logout2'));
const Register2 = React.lazy(() => import('pages/account/Register2'));
const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('pages/account/ForgetPassword2'));

// dashboard
const EcommerceDashboard = React.lazy(() => import('pages/dashboard/Ecommerce'));

const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// charts
const ChartsHome = React.lazy(() => import('pages/charts/ChartHome'));
const ChartView = React.lazy(() => import('pages/charts/ChartView'));

// operations
const AddCalculationQueueMessage = React.lazy(() => import('pages/operations/AddCalculationQueueMessage'));

// strategy
const AllStrategies = React.lazy(() => import('pages/strategy/AllStrategies'));
const StrategyHome = React.lazy(() => import('pages/strategy/StrategyHome'));
const StrategyConfigurationHome = React.lazy(() => import('pages/strategy/StrategyConfigurationHome'));
const StrategyRunHome = React.lazy(() => import('pages/strategy/StrategyRunHome'));
const TradeExecutionHome = React.lazy(() => import('pages/strategy/TradeExecutionHome'));

// data validation
const DataValidationHome = React.lazy(() => import('pages/datavalidation/DataValidationHome'));
const DataValidationJobPage = React.lazy(() => import('pages/datavalidation/DataValidationJobPage'));
const DataValidationCreatePage = React.lazy(() => import('pages/datavalidation/DataValidationCreatePage'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: 'chart/view',
                    element: <LoadComponent component={ChartView} />
                },
                {
                    path: 'chart',
                    element: <LoadComponent component={ChartsHome} />
                },
                {
                    path: 'operations/calcqueuemessage',
                    element: <LoadComponent component={AddCalculationQueueMessage} />
                },
                {
                    path: 'datavalidation/:jobId',
                    element: <LoadComponent component={DataValidationJobPage} />
                },
                {
                    path: 'datavalidation/create',
                    element: <LoadComponent component={DataValidationCreatePage} />
                },
                {
                    path: 'datavalidation',
                    element: <LoadComponent component={DataValidationHome} />
                },
                {
                    path: 'strategy/all',
                    element: <LoadComponent component={AllStrategies} />
                },
                {
                    path: 'strategy/:name',
                    element: <LoadComponent component={StrategyHome} />
                },
                {
                    path: 'strategy/configuration/:id',
                    element: <LoadComponent component={StrategyConfigurationHome} />
                },
                {
                    path: 'strategy/configuration/:configId/run/:runId',
                    element: <LoadComponent component={StrategyRunHome} />
                },
                {
                    path: 'strategy/configuration/:configId/run/:runId/execution/:executionId',
                    element: <LoadComponent component={TradeExecutionHome} />
                },
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'ecommerce',
                            element: <LoadComponent component={EcommerceDashboard} />,
                        },
                    ],
                },
            ]
        }
    ]);
};

export { AllRoutes };
